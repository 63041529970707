import React from "react";
export const CTA = () => {
  return (
    <div className="cta">
      <a href="#Portfolio"  className="btn">
        My Projects
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's talk
      </a>
    </div>
  );
};

