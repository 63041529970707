import React from "react";
import "./portfolio.css";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Portfolio = () => {
  return (
    <section id="Portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      {/* +++++++++++++++++++++++++++++++++++++= */}
      <Swiper
        className="container portfolio__container large-screen"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={3}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/hd3h2nV/Untitled-1-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/4NqHD5H/bashwr-city1.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/rp2xZGk/freez2-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/L6rDWXp/lutka2-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/vdZs3P5/photo-2022-12-24-19-07-33.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/gVGQ0Vf/photo-2022-12-24-19-07-34.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/1dfRswK/photo-2022-12-24-19-07-36.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/Rp29SrH/photo-2022-12-24-19-08-32.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/h8Xdtxw/photo-2022-12-24-19-09-15-2.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/DgpnVhP/photo-2022-12-24-19-09-15.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/KWPThBn/photo-2022-12-24-19-09-16.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/vVH4Whh/teletek1-copy-jpg-3.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
      </Swiper>
      {/* +++++++++++++++++++++++++++++++++++++= */}
      <Swiper
        className="container portfolio__container small-screen"
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={30}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/hd3h2nV/Untitled-1-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/4NqHD5H/bashwr-city1.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/rp2xZGk/freez2-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/L6rDWXp/lutka2-copy.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/vdZs3P5/photo-2022-12-24-19-07-33.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/gVGQ0Vf/photo-2022-12-24-19-07-34.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/1dfRswK/photo-2022-12-24-19-07-36.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/Rp29SrH/photo-2022-12-24-19-08-32.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/h8Xdtxw/photo-2022-12-24-19-09-15-2.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/DgpnVhP/photo-2022-12-24-19-09-15.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/KWPThBn/photo-2022-12-24-19-09-16.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className="portfolio_item">
          <div className="portfolio__item-image">
            <img
              src={"https://i.ibb.co/vVH4Whh/teletek1-copy-jpg-3.jpg"}
              alt=""
              className="p__image"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};
